<template>
  <div>
    <TopNavBar2 class="tnb2"></TopNavBar2>
    <privacy1></privacy1>
    <form1></form1>
    <Footer1></Footer1>
  </div>
</template>

<script>
import TopNavBar2 from '../components/TopNavBar2.vue'
import form1 from '../components/form.vue'
import Footer1 from '../components/footer.vue'
import privacy1 from '../components/privacy1.vue'
export default {
  metaInfo() {
    return {
      title: this.MetaData['title'], // set a title
      titleTemplate: this.MetaData['titleTemplate'], // %s  required
      htmlAttrs: this.MetaData['htmlAttrs'],
      meta: this.MetaData['meta'],
      link: [
        { rel: 'canonical', href: 'https://pennlogisticsllc.com/privacy-policy' }
      ]
    }
  },
  data() {
    return {
      metaData: {

        en: {
          Homepage: {
            title: 'Privacy policy | Pennsylvania Logistics', // set a title
            titleTemplate: '', // %s  required
            htmlAttrs: {
              lang: "en",
              amp: undefined // "amp" has no value
            },
            meta: [
              {
                'name': 'keywords',
                'content': 'freight transportation services, ftl shipping, ltl shipping, trucking, truckload, transportation company',
              },
              {
                'name': 'description',
                'content': 'This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.',
              },
              {
                property: 'og:url',
                content: 'https://pennlogisticsllc.com/privacy-policy',
                 vmid: 'og:url'
              },
              {
                property: 'og:title',
                content: 'Privacy policy | Pennsylvania Logistics',
                vmid: 'og:title'
              },
              {
                property: 'og:description',
                content: 'This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.',
                vmid: 'og:description'
              },
              {
                property: 'og:type',
                content: 'website',
                vmid: 'og:type'
              },
              {
                property: 'og:image',
                content: '../img/fon-block1.jpg',
                vmid: 'og:image'
              },


            ],
            link: [
              { rel: 'canonical', href: 'https://pennlogisticsllc.com/privacy-policy' }
            ]
          },
        },
      },
    }
  },
  name: 'Home',
  components: {
    TopNavBar2, form1, Footer1, privacy1
  },
  computed: {
    MetaData() {
      return this.metaData['en']['Homepage']
    }
  },
}
</script>